body {
	background-color: black;
	background-image: url("./assets/images/BackgroundImage.png");
	background-repeat: no-repeat;

	margin: 0;
	font-family: "Rubik", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: "Rubik", sans-serif;
}

.cards {
	margin: 40px;
	padding-left: 10px;
}

.b-card-image {
	border-radius: 52px;
	width: 360px;
	height: 480px;
	box-shadow: 0px 0px 10px 5px rgb(133, 182, 173);
}

.b-card-image:hover {
	box-shadow: 0px 0px 10px 5px rgb(68, 113, 225);
}

.b-card-info {
	margin-top: 10px;
	min-height: 100px;
}

.b-card-title {
	font-size: 24px;
	margin: 0px;
}

.b-card-subtitle {
	font-size: 16px;
	margin: 0px;
}

.slick-prev {
	left: 1% !important;
	z-index: 1;
}
.slick-next {
	right: 1% !important;
	z-index: 1;
}

#about-section {
	width: 50%;
	background-color: rgba(1, 11, 9, 0.85);
	padding: 50px 10px 30px 10px;
	border-radius: 75px;
	box-shadow: 0px 0px 50px 20px rgb(133, 182, 173);
}

#about-section:hover {
	box-shadow: 0px 0px 50px 20px rgb(68, 113, 225);
}

#form-button {
	background-color: rgb(1, 11, 9);
	border: 1px solid transparent;
	border-radius: 15px;
	box-shadow: 0px 0px 6px 6px rgb(133, 182, 173);
	padding: 10px 30px;
}

#form-button:hover {
	background-color: rgb(58, 136, 122);
	box-shadow: 0px 0px 6px 6px rgb(68, 113, 225);
}

.success-msg {
	color: #57a773;
	padding: 10px 15px;
}

.err-msg {
	color: #f33b23;
	padding: 10px 15px;
}
